import Vue from 'vue';
import Vuex from 'vuex';

import programas from './modules/programas';
import recaptcha from './modules/recaptcha';
import solicitud from './modules/solicitud';
import solicitudDatos from './modules/solicitudDatos';
import spinner from './modules/spinner';
import tiposDocumentos from './modules/tiposDocumentos';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        programas,
        recaptcha,
        solicitud,
        solicitudDatos,
        spinner,
        tiposDocumentos
    }
});