import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    isValid: false
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.isValid = false;
    },    
    'SET_VALID'(state, isValid) {
        state.isValid = isValid;
    },    
};

const getters = {
    getField,

    isValid(state) {
        return state.isValid;
    }
};

const actions = {
    validateRecaptcha( { commit }, token ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');
            
            let recaptcha = {
                token
            }

            apiAxios.post(`public/validate-recaptcha`, recaptcha) 
                .then(() => {
                    commit('SET_VALID', true);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}