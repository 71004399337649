import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import extAxios from '../../services/extAxios';
import helpers from '../../mixins/helpers';
import { saveAs } from 'file-saver';

const state = {
    list: [],

    programaId: '',
    programa: '',
    descripcion: '',
    reglamentoUrl: '',
    formularios: []
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.list = [];
    },
    'SET_PROGRAMAS'(state, list) {
        state.list = list.sort();
    },
    'SELECCIONAR_PROGRAMA' (state, programaId) {
        let programa = state.list.find(t => t.id == programaId);
        if (programa) {
            state.programaId = programaId;
            state.programa = programa.nombre;
            state.descripcion = programa.descripcion;
            state.reglamentoUrl = programa.reglamentoUrl;
            state.formularios = programa.formularios;
        }
    }
};

const actions = {
    getProgramas: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            apiAxios.get('public/programas')
                .then(res => {
                    commit('SET_PROGRAMAS', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    seleccionarPrograma: ({commit}, programaId) => {
        return new Promise((resolve, reject) => {
            commit('SELECCIONAR_PROGRAMA', programaId);
            resolve();
        })
    },
    descargarReglamento: ( {}, reglamentoUrl ) => {
        return new Promise((resolve, reject) => {
            extAxios.get(reglamentoUrl, {responseType: 'blob'})
                .then(res => {
                    if (res) {
                        saveAs(res.data, 'reglamento.pdf');
                    }
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,

    list(state) {
        return state.list;
    },
    programa(state) {
        return {
            programa: state.programa,
            descripcion: state.descripcion,
            reglamentoUrl: state.reglamentoUrl,
            formularios: state.formularios
        };
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}